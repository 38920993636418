// https://router.vuejs.org/zh/
import { createRouter, createWebHashHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 导入路由组件
import { mergeRoutes } from './queue'
import * as myOrderRouter from './my_order'
import * as photo3dRouter from './photo_3d'
import * as shortUrlRouter from './short_url'
import mian from '@/views/index.vue'
import User from '@/views/user/index.vue'
import Person from '@/views/user/person.vue'
import About from '@/views/about/index.vue'
import Login from '@/views/login/index.vue'
import { useTokenStore } from '@/stores/token'
import {useStore} from '@/stores'
import { config } from '@/config/config'

NProgress.configure({ showSpinner: true, parent: '#app' })

// 定义路由，每个路由都需要映射到一个组件
const routes = [
  {
    path: '/',
    name: 'main',
    component: mian,
    meta: {
      needGuard: true,
      title: '首页',
    },
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      needGuard: true,
      title: "个人中心",
    },
  },
  {
    path: "/person",
    name: "person",
    meta: {
      title: "个人信息"
    },
    component: Person
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      title: '关于我们',
    },
  },
]

mergeRoutes(routes)
myOrderRouter.mergeRoutes(routes)
photo3dRouter.mergeRoutes(routes)
shortUrlRouter.mergeRoutes(routes)

// 创建路由实例并传递 `routes` 配置
const router = createRouter({
  history: createWebHashHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
})

router.beforeEach((_to, _from, next) => {
  const tokenStore = useTokenStore()
  const store = useStore()
  const token = _to.query.token
  if (token) {
    tokenStore.login(String(token))
    let url = store.redirectUrl()

    if (_to.path !== '/queue') {
      if (url) {
        store.setRedirect('')
        next(url)
        return
      }
    } else {
      if (url) {
        store.setRedirect('')
      }
    }
  }
  
    // TODO: 根据API获取用信息
  window.document.title = `${config.brand.$t} - ${(_to.meta.title as string || '相册').$t}`
  store.updateTitle(_to.meta.title.$t); // 导航栏标题变化
  NProgress.start() // start progress bar
  const needGuard = _to.meta.needGuard
  if (needGuard) {
    if (!tokenStore.isLoggedIn()) {
      // 记录本地访问路径
      store.setRedirect(_to.fullPath);
      next(`/login`)
    } else {
      if (_to.path === '/') {
        next('/myOrder')
      } else {
        next()
      }
    }
      
  } else {
    if (_to.path === '/login') {
      if (!tokenStore.isLoggedIn()) {
        next()
      } else {
        next('/myOrder')
      }
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})

// 导出路由实例，并在 `main.ts` 挂载
export default router
