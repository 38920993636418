<template>
    <van-nav-bar v-if="!isWechat()" :title="store.navTitle" left-text="返回" left-arrow
  @click-left="returnClick" safe-area-inset-top/>
    <div>
        <div class="page">
            <div class="section">
                <div class="section-title">
                    <div class="s-title">SUWA3D</div>
                    <div class="l-title">关于我们</div>
                </div>
                <div class="description">速相科技成立于2016年，总部在福建厦门<br />目前是全球首家商业化3D人像摄影专业机构<br />产品材料安全环保，细节还原度高<br />旗下的品牌“速哇”为国内首家数字人3D真人手办连锁品牌<br />速哇3D手办亲人、朋友、恋人、宠物的各种美好时刻记录下来</div>
                <div class="info-box">
                    <img src="@/assets/about/mail.png" class="info-icon"/>
                    <div class="info-detail">
                        <div class="info-label">邮箱</div>
                        <div class="info-value">business@suwa3d.com</div>
                    </div>
                </div>
                <div class="info-box" @click="call(1)">
                    <img src="@/assets/about/hands.png" class="info-icon"/>
                    <div class="info-detail">
                        <div class="info-label">合作热线</div>
                        <div  class="info-value">400&nbsp;6667&nbsp;215</div>
                    </div>
                </div>
                <div class="info-box" @click="call(2)">
                    <img src="@/assets/about/tel.png" class="info-icon"/>
                    <div class="info-detail">
                        <div class="info-label">招商电话</div>
                        <div class="info-value">18046215201</div>
                    </div>
                </div>
            </div>
            <div class="info-box" @click="call(1)">
                <img src="@/assets/about/hands.png" class="info-icon"/>
                <div class="info-detail">
                    <div class="info-label">合作热线</div>
                    <div  class="info-value">400&nbsp;6667&nbsp;215</div>
                </div>
            </div>
            <div class="info-box" @click="call(2)">
                <img src="@/assets/about/tel.png" class="info-icon"/>
                <div class="info-detail">
                    <div class="info-label">招商电话</div>
                    <div class="info-value">18046215201</div>
                </div>
            </div>
        </div>
        <div class="section">
            <div class="section-title">
                <div class="s-title">SUWA3D</div>
                <div class="l-title">全国门店</div>
            </div>
            <img src="@/assets/about/map.png" class="map_img"/>
            <div class="group_6">
                <div class="shop-count">
                    <div class="under_border"></div>
                    <div class="count">49+</div>
                </div>
                <div class="text_25">家门店</div>
                <div class="point">
                    <div class="group_7"></div>
                    <div class="group_8"></div>
                </div>
                <div class="text-wrapper_4">
                    <div class="text_26">速哇加盟门店城市</div>
                    <div class="text_27">速哇待开业门店</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {useStore} from '@/stores'
const store = useStore();
import { useTokenStore } from '@/stores/token'
const tokenStore = useTokenStore();
const userInfo = ref("")

const {isWechat} = tokenStore;

onMounted(()=> {
  userInfo.value = tokenStore.userInfo;
})
const returnClick = () => history.back()
const call = (num: number) => {
    const a = document.createElement("a");
    let phoneNum = ""
    switch(num) {
        case 1: phoneNum = "4006667215";break;
        case 2: phoneNum = "18046215201";break;
    }
    a.href = "tel:" + phoneNum;
    a.click();
}
</script>

<style lang="less" scoped>
.page {
    background-color: rgba(255, 255, 255, 1.000000);
    position: relative;
    width: 375px;
    height: 100vh;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}


.section {
    width: 375px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    .section-title {
        width: 157px;
        height: 40px;
        flex-direction: row;
        display: flex;
        margin: 25px auto 0 auto;
        .s-title {
            overflow-wrap: break-word;
            font-size: 36px;
            font-family: Source Han Sans CN-Heavy;
            font-weight: 900;
            text-align: center;
            white-space: nowrap;
            line-height: 36px;
            webkit-background-clip: text;
            webkit-text-fill-color: transparent;
            color: mintcream;
        }

        .l-title {
            overflow-wrap: break-word;
            color: rgba(61, 61, 61, 1.000000);
            font-size: 24px;
            font-family: Source Han Sans CN-Bold;
            font-weight: 700;
            text-align: center;
            white-space: nowrap;
            line-height: 24px;
            margin: 16px 30px 0 -126px;
        }
    }
}

.description {
    width: 345px;
    height: 108px;
    overflow-wrap: break-word;
    color: rgba(102, 102, 102, 1.000000);
    font-size: 12px;
    font-family: Source Han Sans CN-Regular;
    font-weight: normal;
    text-align: center;
    line-height: 18px;
    margin: 30px auto 15px auto;
}

.info-box {
    width: 205px;
    height: 54px;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 0 auto;
    .info-icon {
        width: 54px;
        height: 54px;
    }
    .info-detail {
        width: 131px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .info-label {
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1.000000);
            font-size: 16px;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            white-space: nowrap;
            line-height: 16px;
        }
        .info-value {
            overflow-wrap: break-word;
            color: rgba(153, 153, 153, 1.000000);
            font-size: 12px;
            font-family: Source Han Sans CN-Regular;
            font-weight: normal;
            white-space: nowrap;
            line-height: 12px;
        }
    }
}

.text-group_7 {
    width: 157px;
    height: 40px;
    flex-direction: row;
    display: flex;
    margin: 50px 0 31px 109px;
}

.map_img {
    width: 345px;
    height: 283px;
    margin: 50px auto 0 auto;
}

.group_6 {
    width: 305px;
    height: 44px;
    flex-direction: row;
    display: flex;
    margin: 30px auto;
}

.shop-count {
    position: relative;
    width: 73px;
    height: 44px;
    display: flex;
    flex-direction: column;
}

.under_border {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(90deg, rgba(85, 176, 56, 0.000000) 0, rgba(108, 217, 73, 1.000000) 100.000000%);
    width: 73px;
    height: 16px;
    margin-top: 28px;
}

.count {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 73px;
    height: 36px;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1.000000);
    font-size: 40px;
    font-family: Source Han Sans CN-Heavy;
    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    line-height: 36px;
}

.text_25 {
    width: 48px;
    height: 16px;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1.000000);
    font-size: 16px;
    font-family: Source Han Sans CN-Heavy;
    font-weight: 900;
    text-align: center;
    white-space: nowrap;
    line-height: 16px;
    margin: 18px 0 0 5px;
}

.point {
    width: 8px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 3px 0 0 49px;
}

.group_7 {
    background-color: rgba(85, 176, 56, 1.000000);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    display: flex;
    flex-direction: column;
}

.group_8 {
    background-color: rgba(255, 94, 94, 1.000000);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    margin-top: 22px;
    display: flex;
    flex-direction: column;
}

.text-wrapper_4 {
    width: 112px;
    height: 44px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text_26 {
    width: 112px;
    height: 14px;
    overflow-wrap: break-word;
    color: rgba(61, 61, 61, 1.000000);
    font-size: 14px;
    font-family: Source Han Sans CN-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
}

.text_27 {
    width: 83px;
    height: 14px;
    overflow-wrap: break-word;
    color: rgba(61, 61, 61, 1.000000);
    font-size: 14px;
    font-family: Source Han Sans CN-Regular;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
    margin-top: 16px;
}
</style>