<script lang="tsx" setup name="sw-article">
const list = ref([
  {
    title: '元宇宙与少儿科普的关系',
    src: 'https://wechat.suwa3d.com/data/meta/a03.jpg',
    link: 'https://mp.weixin.qq.com/s?__biz=MzkwMDYzMTY3NA==&mid=2247483875&idx=1&sn=8d99989e8c016bc51ae27a80244bbb86&chksm=c0405cf6f737d5e04d2c711922001cdb19307743893f1193bb76043cbf8b7fef360652b73a81#rd',
  },
  {
    title: '小课堂————什么是元宇宙？',
    src: 'https://wechat.suwa3d.com/data/meta/a02.jpg',
    link: 'https://mp.weixin.qq.com/s?__biz=MzkwMDYzMTY3NA==&mid=2247483876&idx=1&sn=7c549372edf3ec3dfa7e9bb5935ec2fe&chksm=c0405cf1f737d5e787d2cbf6d05f77a5de185a2cea058ef07f3b421875a289e3e4996e6fd745#rd',
  },
  {
    title: '小课堂————什么是虚拟数字人？',
    src: 'https://wechat.suwa3d.com/data/meta/a01.jpg',
    link: 'https://mp.weixin.qq.com/s?__biz=MzkwMDYzMTY3NA==&mid=2247483889&idx=1&sn=179e9db11d2f386a48635bc2e4fae5ff&chksm=c0405ce4f737d5f2b94c9aa55cbcf45d4005081d7537578878c6c6d8474ead446c9ac65a67fe#rd',
  },
])

function onClick(item: any) {
  window.location.href = item.link
}
</script>

<template>
  <van-grid :border="false" :column-num="1">
    <van-grid-item v-for="(item, i) in list" :key="i" @click="onClick(item)">
      <van-image
        :src="item.src"
      />
      <p>{{ item.title }}</p>
    </van-grid-item>
  </van-grid>
</template>

<style>

</style>
