import i18next from "i18next";
import { SimpleTFunction } from "i18next-vue";
let translation: SimpleTFunction;
export function set(f: SimpleTFunction) {
	translation = f;
}

export function t(key: string, defaultValue: string) {
	if (translation) {
		let ret = translation(key, defaultValue);
		return ret;
	}
	return defaultValue;
}



const mappingConfig: any = {
	valid: false
};

const load = () => {
	if (!mappingConfig.valid) {
		const data = i18next.getDataByLanguage("zh-CN")?.translation;
		for (const key in data) {
			mappingConfig[String(data[key])] = key;
		}
		mappingConfig.valid = true;
	}
};

export function toValue(t: any, key: string) {
	load();
	if (mappingConfig[key]) {
		return t(mappingConfig[key]);
	}
	return key;
}


export function toValueWithout(key: string) {
	load();
	if (!mappingConfig.valid) {
		const data = i18next.getDataByLanguage("zh-CN")?.translation;
		for (const key in data) {
			mappingConfig[String(data[key])] = key;
		}
		mappingConfig.valid = true;
	}


	if (mappingConfig[key]) {
		return t(mappingConfig[key], key);
	}
	return key;
}


Object.defineProperty(String.prototype, "$t", {
	get: function $t() {
		return toValueWithout(this);
	}
});

String.prototype.t = function $t() {
	return toValueWithout(this);
}
