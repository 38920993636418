<script setup lang="ts">
import hello from 'hellojs'
import type { Ref } from 'vue'
import { normalize } from './helpers/transfer'
// import { useUserStore } from '@/stores/user'
import router from '@/router'
import {useStore} from '@/stores'
import { useTokenStore } from '@/stores/token'

import * as authApi from '@/api/auth'

interface Item {
  icon: string
  name: string
  text: string
}

const logins: Ref<Item[]> = ref([
  // {
  //   icon: 'microsoft',
  //   name: 'windows',
  //   text: 'windows',
  // },
  {
    icon: 'googleplus',
    name: 'google',
    text: 'G+',
  },
  // {
  //   icon: 'facebook',
  //   name: 'facebook',
  //   text: 'Facebook',
  // },
  // {
  //   icon: 'apple',
  //   name: 'apple',
  //   text: 'apple',
  // },
  {
    icon: 'twitter',
    name: 'twitter',
    text: 'twitter',
  },
  // {
  //   icon: 'wechat',
  //   name: 'wechat',
  //   text: 'wechat',
  // },
])

function onBtn(item: Item) {
  switch (item.name) {
    case 'wechat':
      window.location.href = `https://wechat.api.suwa3d.com/api/auth/page?callback${encodeURIComponent(window.location.href)}`
      return
  }

  hello(item.name).login().then((d: any) => {
    console.log('d', d)
    // hello(item.name).api('me').then((d: any) => {
    //   console.log('d', d)
    //   const data: any = normalize(item.name, d)
    //   // const user = useUserStore()
    //   // user.login(d)
    //   // useRouter().replace('/')
    // }, (e: any) => {
    //   console.log('/me error', e)
    // })
  }, () => {
    // console.log('login failed')
  })
}

const stores = useStore();

hello.on('auth.login', (r: any) => {
  hello(r.network).api('/me', 'get').then((d) => {
    const data: any = normalize(r.network, d)
    authApi.getUser(data).then((res: any) => {
      useTokenStore().login(res)
      let url = stores.redirectUrl();
      if (url) {
        stores.setRedirect('')
        router.replace(url)
        return
      }

      router.replace('/')
    }, (e: any) => {
      console.log('auth.login /me error', e)
    })
  })
})

// TODO: 这边需要进行配置
hello.init({
  facebook: '1875721406193406',
  windows: '000000004403AD10',
  google: '495792315385-mj44iuov1bupumvl6evmmcvag39mi38t.apps.googleusercontent.com',
  twitter: 'ICw3GtdIivmHPI5qTLD0gZDbN',
}, {
  redirect_uri: './oauth.html',
  scope: 'email',
})
</script>

<template>
  <div class="container">
    <van-divider>Social Login</van-divider>
    <div v-for="(item, i) in logins" :key="i" class="l-btn">
      <van-button plain icon-prefix="iconfont" :icon="item.icon" type="primary" size="large" @click="onBtn(item)">
        {{ item.text }}
      </van-button>
    </div>
  </div>
</template>

<style scoped lang="less">
.l-btn{
  margin: .4rem;
  width: 100%;
}
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
</style>
