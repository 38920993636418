<script lang="tsx" setup>
import { useRoute } from 'vue-router'
import type { Ref } from 'vue'
import OrderTrackItem from './components/orderTrackItem.vue'
import { orderTrack as orderTrackApi } from '@/api/short_url'

const code = ref('')
const data: Ref<any> = ref({})
const activities = ref([])
const route = useRoute()
const loading = ref(false)

onMounted(() => {
  code.value = route.query.code as string
  getInfo()
})

function getInfo() {
  loading.value = true
  orderTrackApi(code.value).then((res: any) => {
    data.value = res || {}
    formatData(data.value)
  }).finally(() => {
    loading.value = false
  })
}

function formatData(res: any) {
  const tempData = res
  // 进行逆序降序排序
  const tempRes = tempData.sort((a, b) => {
    return new Date(b.time).getTime() - new Date(a.time).getTime()
  })
  const tempNewRes = []
  for (const i in tempRes) {
    // 说明已经到头了
    // 判断当前是不是待修模状态
    const tempv1 = tempRes[i]
    const tempv2 = tempRes[parseInt(i) + 1]
    if (tempv1?.status === 5000) {
      // 判断上下两个状态是否为待修模，并且订单id 是一样的，这种情况是不科学的，前端先手动过滤，后续找到真实原因
      if (
        tempv2?.status === 5000
                && tempv2?.order_id === tempv1?.order_id
      )
        continue
    }

    const msg = tempRes[i]
    if (msg.msg.includes('已称完净重') && msg.msg.includes('等待打包')) {
      const pattern = /已称完净重\(([\d\.?]+)g\),等待打包/
      const matches = msg.msg.match(pattern)

      if (matches)
        msg.msg = `${'已称完净重'}(${matches[1]}g),${'等待打包'}`
    }

    tempNewRes.push(msg)
  }

  activities.value = tempNewRes
}
</script>

<template>
  <van-steps direction="vertical" :active="0">
    <OrderTrackItem v-for="(activity, index) in activities" :key="index" :activity="activity" />
  </van-steps>
  <Loading :loading="loading" />
</template>

<style lang="less" scoped></style>
