
<template>
  <div class="container">
    <Brand />
    <van-divider>——内容精选——</van-divider>
    <Article />
    <div class="empty" />
    <Tabbar></Tabbar>
  </div>
  
</template>
<script setup lang="ts">
import { showLoadingToast, showToast,  closeToast } from "vant";
import { localStorage } from '@/utils/local-storage'
import {imgSrc} from "@/utils/utils"
const router = useRouter()
const loading = ref(true)
const showAvaModal = ref(false)
const isPhone = ref(false)
const userInfo = ref("")
const mode = ref("")
const orderList = ref([])
const page = ref(1)

const active = ref('home')

onMounted(()=>{
    isLogin();
    showLoadingToast({
      message: '加载中...'.$t,
      forbidClick: true,
    });
    setTimeout(()=> {
        closeToast();
        loading.value = false;
    }, 1000)
}) 
// 登录判断
const isLogin = () => {
    // let data = localStorage.get('userInfo');
    // userInfo.value = data || "";
    // isPhone.value = data.mobile && true;
    // if (data.mobile || isPhone.value) {
    //   orderList.value = [];
    //   page.value = 1;
    //   getOrderList(data)
    // }
}

//跳转外部连接
const handleToWebSite = () => {
    // 如果未登录先跳转到登陆页
    if (!userInfo.value && !isPhone.value) { // 用户登录
      router.push({
            // 跳转到webview页面
            // url: "/pages/login/login",
        });
        return
    }
    // const url = 'https://wechat.api.suwa3d.com/applyTakephoto/page'; // 跳转的外链
    // const navtitle = '立即预约'; // 这个标题是你自己可以设置的
    router.push({
        // 跳转到webview页面
        // path: `/pages/webview/webview?url=${url}&nav=${navtitle}`,
    });
}

// 复制订单ID
const copyOrderId = (e) => {
    // wx.setClipboardData({
    //     data: e.currentTarget.dataset.text.toString(),
    //     success: function (res) {
    //         wx.getClipboardData({ //这个api是把拿到的数据放到电脑系统中的
    //             success: function (res) {
    //                 console.log(res.data) // data
    //                 wx.showToast({
    //                     title: '复制成功',
    //                 })
    //             }
    //         })
    //     }
    // })
}

// 状态判断
const statusJudge = (data) => {
  data.map((item) => {
      switch (item.status) {
          case 1000:
              item.statusName = "上传完成";
              item.statusColor = 0;
              break;
          case 2000:
              item.statusName = "待门店提交建模";
              item.statusColor = 0;
              break;
          case 3000:
              item.statusName = "待建模";
              item.statusColor = 0;
              break;
          case 4000 :
              item.statusName = "建模中";
              item.statusColor = 0;
              break;
          case 4100 :
              item.statusName = "建模完成";
              item.statusColor = 1;
              break;
          case 5000 :
              item.statusName = "待修模";
              item.statusColor = 0;
              break;
          case 6000 :
              item.statusName = "修模中";
              item.statusColor = 0;
              break;
          case 7000 :
              if (item.digital_type == 1) {
                  item.statusName = "3D相册已创建";
              } else {
                  item.statusName = "修模完成";
              }

              item.statusColor = 1;
              break;
          case 7011 :
              item.statusName = "3D相册已创建";
              item.statusColor = 1;
              break;
          case 7020 :
              item.statusName = "待打印";
              item.statusColor = 0;
              break;
          case 8000 :
              item.statusName = "打印中";
              item.statusColor = 0;
              break;
          case 8100 :
              item.statusName = "后道工序处理";
              item.statusColor = 0;
              break;
          case 8110 :
              // item.statusName = "称净重";
              // item.statusColor = 0;
              break;
          case 9000 :
              item.statusName = "快递中";
              item.statusColor = 1;
              break;
          case 10000 :
              item.statusName = "订单已完成";
              item.statusColor = 1;
              break;
          default:
              return false
      }
      if (item.status >= 7020 && item.status < 8000) {
          item.statusName = "待打印";
          item.statusColor = 0;
      }
      if (item.status > 6000 && item.status < 7000) {
          item.statusName = "待审核";
          item.statusColor = 0;
      }
      if (item.status > 8000 && item.status < 9000) {
          item.statusName = "后道工序处理中";
          item.statusColor = 0;
      }
      if (item.status >= 9000 && item.status < 10000) {
          item.statusName = "快递中";
          item.statusColor = 1;
      }
  })
  return data
}

// 购买手办
// goBuy(e) {
//     const u = wx.getStorageSync('userInfo');
//     var pid = e.currentTarget.dataset.pid;
//     var cn = e.currentTarget.dataset.cn;
//     var oid = e.currentTarget.dataset.oid;
//     var cid = e.currentTarget.dataset.cid;
//     console.log(`/pages/cart/cart?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}`)
//     wx.navigateTo({
//         url: `/pages/cart/cart?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}`,
//     });
// }

// 跳转到照片的详情
const goPhoto = (e) => {
    // const {id} = e.currentTarget.dataset;
    router.push({
        // path: `/pages/message/detail/detail?id=${id}&hidden=true`,
    });
}

// 跳转至songDetail页面
const toOrderDetail = (e) => {
    // var orderInfo = e.currentTarget.dataset.row;
    // // 路由跳转传参： query参数
    // router.push({
    //     // 不能直接将song对象作为参数传递，长度过长，会被自动截取掉
    //     // url: '/pages/songDetail/songDetail?songPackage=' + JSON.stringify(songPackage)
    //     path: '/pages/orderdetail/orderdetail',
    //     success: function (res) {
    //         res.eventChannel.emit("orderInfo", orderInfo)
    //     }
    // })
}

// 订单列表遍历
const getOrderList = (userInfo) => {
  // showLoadingToast({
  //     message: '加载中...',
  //     forbidClick: true,
  //   });
  if (userInfo) {
      var obj = {}
      // wx.getSystemInfo({
      //     success(el) {
      //         obj = {
      //             version: el.version,    // 微信版本
      //             brand: el.brand,		// 设备品牌
      //             model: el.model,		// 设备型号
      //             platform: el.platform,  // 客户端平台
      //             system: el.system,      // 操作系统及版本
      //         }
      //     }
      // })
      let data = {
          open_id: userInfo.openid,
          union_id: userInfo.unionid,
          page: page.value
      }
      data = Object.assign(data, obj)
      // app.api.getOrderList(data).then(res => {
      //     if (res.code == 1000) {
      //         let data = res.data;
      //         let {orderList} = that.data;
      //         data = orderList.length >= 6 ? [...orderList, ...data] : data
      //         data = that.statusJudge(data);
      //         that.setData({
      //             orderList: data,
      //             page: that.data.page + 1
      //         })
      //         wx.hideLoading();
      //         wx.stopPullDownRefresh();
      //     }
      // }).catch(err => {
      //     console.log(err);
      // })
  }
}

// const onReachBottom = () {
//     // 懒加载订单列表
//     if (this.data.orderList.length >= 6) {
//         let userInfo = this.data.userInfo || wx.getStorageSync('userInfo');
//         this.getOrderList(userInfo)
//     }
// }

// 文章跳转
const articleJump = (e) => {
    // const {id, title} = e.currentTarget.dataset
    // let param = encodeURIComponent(id);
    // router.push({
    //     // 跳转到webview页面
    //     path: `/pages/webview/webview?url=${param}&nav=${title}`,
    // });
}

// 跳转至songDetail页面
const toAlbum = (e) => {
        //获取用户的信息
        // const u = wx.getStorageSync('userInfo');
        // var pid = e.currentTarget.dataset.pid;
        // var cn = e.currentTarget.dataset.cn;
        // var oid = e.currentTarget.dataset.oid;
        // var cid = e.currentTarget.dataset.cid;
        // var status = e.currentTarget.dataset.status;
        // var remainingExperienceStatus = e.currentTarget.dataset.remain_status;
        // var expireTime = e.currentTarget.dataset.expire_time;
        // var digitalOrderId = e.currentTarget.dataset.digital_order_id;
        // var digitalType = e.currentTarget.dataset.digital_type;
        // var createTime = e.currentTarget.dataset.create_time;
        // var titleCode = e.currentTarget.dataset.title_code;
        // console.log(e.currentTarget.dataset)
        // //判断createTime时间是否在2023-04-15 00:00:00 之后
        // wx.request({
        //     url: config.url + '/miniProgram/judgeP3dLogOrderTime',
        //     data: {photoTime: createTime, pid},
        //     header: {'content-type': 'application/json'},
        //     success: (res) => {
        //         if (res.data.data.use_status != 1) {
        //             wx.showToast({
        //                 title: '该订单不支持3D相册，需重新拍照',
        //                 icon: 'none',
        //                 duration: 2000
        //             })
        //             return
        //         }

        //         const url = `/package3D/pages/3d/3d?openid=${u.openid}`
        //             + `&unionid=${u.unionid}&id=${pid}&oid=${oid}`
        //             + `&cn=${cn}&cid=${cid}&expireTime=${expireTime}`
        //             + `&digitalType=${digitalType}`
        //             + `&status=${status}`
        //             + `&titleCode=${titleCode}`
        //             + `&remainingExperienceStatus=${remainingExperienceStatus}`

        //         console.log("url", url);

        //         if (digitalOrderId == 0) {
        //             wx.navigateTo({
        //                 url: url,
        //             });
        //             return
        //         }

        //         if (status < 4100) {
        //             wx.showToast({
        //                 title: '3D相册制作中',
        //                 icon: 'none',
        //                 duration: 2000
        //             })
        //             return
        //         }


        //         //获取时间戳
        //         // const timestamp = Date.parse(new Date());
        //         // const url = `http://172.16.20.5:3000/?t=${Date.now()}#/model?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}&expireTime=${expireTime}&remainingExperienceStatus=${remainingExperienceStatus}`
        //         // const url = `https://wechat.suwa3d.com/3d/index.html?t=${Date.now()}#/model?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}`
        //         // const navtitle = '3D相册'; // 这个标题是你自己可以设置的
        //         wx.navigateTo({
        //             // 跳转到webview页面
        //             // url: `/pages/webview/webview?nav=${navtitle}&url=${encodeURIComponent(url)}`,
        //             url: url,
        //         });
        //     },
        //     fail: (err) => {
        //         console.log(err);
        //     }
        // })

    }

const gotoAddPrint = (e) => {
    // const u = wx.getStorageSync('userInfo');
    // var pid = e.currentTarget.dataset.pid;
    // var oid = e.currentTarget.dataset.oid;
    // var cn = e.currentTarget.dataset.cn;
    // var cn = e.currentTarget.dataset.oid;
    // var cid = e.currentTarget.dataset.cid;
    // wx.navigateTo({
    //     // 跳转到webview页面
    //     url: `/pages/cart/cart?openid=${u.openid}&unionid=${u.unionid}&id=${pid}&oid=${oid}&cn=${cn}&cid=${cid}`,
    // });
}

</script>

<style lang="less" scoped>

.container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 0px 15px 35px 15px;
    margin-top: 4px;
}

</style>
