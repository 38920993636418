// https://router.vuejs.org/zh/
import 'nprogress/nprogress.css'

import actions from '@/views/short_url/actions.vue'
import orderInfo from '@/views/short_url/orderInfo.vue'
import orderTrack from '@/views/short_url/orderTrack.vue'
import printOrderInfo from '@/views/short_url/printOrderInfo.vue'
// 定义路由，每个路由都需要映射到一个组件
const routes = [
  {
    path: '/shortUrl/actions',
    name: 'shortUrl.actions',
    component: actions,
    meta: {
      needGuard: true,
      title: '功能',
    },
  },
  {
    path: '/shortUrl/orderInfo',
    name: 'shortUrl.orderInfo',
    component: orderInfo,
    meta: {
      needGuard: true,
      title: '订单信息',
    },
  },
  {
    path: '/shortUrl/orderTrack',
    name: 'shortUrl.orderTrack',
    component: orderTrack,
    meta: {
      needGuard: true,
      title: '订单履历',
    },
  },
  {
    path: '/shortUrl/printOrderInfo',
    name: 'shortUrl.printOrderInfo',
    component: printOrderInfo,
    meta: {
      needGuard: true,
      title: '打印信息',
    },
  },
]

export function mergeRoutes(allRoutes: any[]) {
  allRoutes.push(...routes)
}
