<script lang="tsx" setup name="sw-brand">
import { localStorage } from '@/utils/local-storage'
let brandList = ref()

let brandList_cn = ref([
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner1.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner2.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner3.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner4.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner5.png',
  },
])
let brandList_tw = ref([
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner1_tw.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner2_tw.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner3_tw.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner4_tw.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner5_tw.png',
  },
])
let brandList_en = ref([
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner1_en.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner2_en.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner3_en.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner4_en.png',
  },
  {
    pic: 'https://wechat.suwa3d.com/data/banner/banner5_en.png',
  },
])
const lang = ref("");
onMounted(()=> {
  lang.value = localStorage.get("i18nextLng");console.log("lang.value", lang.value)
})
watch(lang, (val)=> {
  switch(val) {
    case "zh-CN": brandList.value = brandList_cn.value;break;
    case "zh-TW": brandList.value = brandList_tw.value;break;
    case "en": brandList.value = brandList_en.value;break;
  }
}, { immediate: true })
</script>

<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item v-for="(item, i) in brandList" :key="i">
      <van-image :src="item.pic" />
    </van-swipe-item>
  </van-swipe>
</template>

<style>
.my-swipe {
  width: 345px;
}
</style>
