<script lang="tsx" setup>
import { useRoute } from 'vue-router'
import * as Dict from './dict'
import { actions as actionsApi } from '@/api/short_url'
import router from '@/router'
import Loading from '@/components/loading/index.vue'
const code = ref('')
const actions = ref([])
const loading = ref(false)

const route = useRoute()
onMounted(() => {
  code.value = route.query.code as string
  getActions()
})

function onAction(action: any) {
  switch (action.value) {
    case Dict.OperationWebsiteId:
      window.location.href = action.jump_url
      break
    case Dict.OperationMiniProgramId:
      window.location.href = action.jump_url
      break
    case Dict.OrderHistoryId:
      router.push(`/shortUrl/orderTrack?code=${code.value}&action=orderTrack`)
      break
    case Dict.OrderInfoId:
      router.push(`/shortUrl/orderInfo?code=${code.value}&action=orderInfo`)
      break
    case Dict.PrintOrderInfoId:
      router.push(`/shortUrl/printOrderInfo?code=${code.value}&action=printOrderInfo`)
      break
  }
}

function getActions() {
  loading.value = true
  actionsApi(code.value).then((res: any) => {
    actions.value = res || []
  }).finally(() => {
    loading.value = false
  })
}
</script>

<template>
  <van-cell-group>
    <van-cell
      v-for="action in actions"
      :key="action.value"
      :title="action.label"
      :clickable="true"
      @click="onAction(action)"
    >
      点击查看
    </van-cell>
  </van-cell-group>
  <Loading :loading="loading" />
</template>

<style lang="less" scoped></style>
