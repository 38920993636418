export function normalizeStatus(data: any[]) {
    data.forEach((item: any) => {
      switch (item.status) {
        case 1000:
          item.statusName = '上传完成'
          item.statusColor = 0
          break
        case 2000:
          item.statusName = '待门店提交建模'
          item.statusColor = 0
          break
        case 3000:
          item.statusName = '待建模'
          item.statusColor = 0
          break
        case 4000:
          item.statusName = '建模中'
          item.statusColor = 0
          break
        case 4100:
          item.statusName = '建模完成'
          item.statusColor = 1
          break
        case 5000:
          item.statusName = '待修模'
          item.statusColor = 0
          break
        case 6000:
          item.statusName = '修模中'
          item.statusColor = 0
          break
        case 7000:
          if (item.digital_type === 1)
            item.statusName = '3D相册已创建'
          else
            item.statusName = '修模完成'
  
          item.statusColor = 1
          break
        case 7011:
          item.statusName = '3D相册已创建'
          item.statusColor = 1
          break
        case 7020:
          item.statusName = '待打印'
          item.statusColor = 0
          break
        case 8000:
          item.statusName = '打印中'
          item.statusColor = 0
          break
        case 8100:
          item.statusName = '后道工序处理'
          item.statusColor = 0
          break
        case 8110:
          // item.statusName = "称净重";
          // item.statusColor = 0;
          break
        case 9000:
          item.statusName = '快递中'
          item.statusColor = 1
          break
        case 10000:
          item.statusName = '订单已完成'
          item.statusColor = 1
          break
        default:
          return false
      }
      if (item.status >= 7020 && item.status < 8000) {
        item.statusName = '待打印'
        item.statusColor = 0
      }
      if (item.status > 6000 && item.status < 7000) {
        item.statusName = '待审核'
        item.statusColor = 0
      }
      if (item.status > 8000 && item.status < 9000) {
        item.statusName = '后道工序处理中'
        item.statusColor = 0
      }
      if (item.status >= 9000 && item.status < 10000) {
        item.statusName = '快递中'
        item.statusColor = 1
      }
    })
    return data
}

//订单状态的信息展示
export function orderStatus(status,digital_type=0){
    if (!status) {
        return ""
    }
    status = status.toString()
    var statusMsg = {
        "1000":"上传完成",
        "2000":"待门店提交建模",
        "3000":"待建模",
        "4000":"建模中",
        "4100":"建模完成",
        "5000":"待修模",
        "6000":"修模中",
        //大于6000 小于 7000  处于待审核
        "6100":"待团队审核",
        "6200":"待平台审核",
        "7000":"修模完成",
        "7011":"3D相册已创建",
        "7020":"待打印",
        "8000":"打印中",
        //大于8000 小于 9000  处于后道工序处理中
        "8100":"后道工序处理中",
        //"8110":"称净重",
        "9000":"快递中",
        "10000":"订单已完成",
    }

    if (status >= 7000 && digital_type == 1) {
        return "3D相册已创建"
    }
    if (status >= 4100 && digital_type == 4) {
        return "建模完成"
    }
    if (status >= 7020 && status < 8000) {
        return "待打印"
    }
    if (status > 6000 && status < 7000) {
        return "待审核"
    }
    if (status > 8000 && status < 9000) {
        return "后道工序处理中"
    }
    if (status >= 9000 && status < 10000) {
        return "快递中"
    }
    return statusMsg[status]
}

//图片数据的拼接转换
export function imgSrc(imgSrc: string){
    var t = Date.now();
    var imagePath = "https://3dview.suwa3d.com/"+imgSrc+"?t="+t;
    return imagePath
}

// jwt解析
export function parseJwt(token: string) {
    if (!token) {
      return {} as any;
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    let data = {};
    try {
     data =  JSON.parse(jsonPayload);  
    } catch (e) {
      data = {};
      console.log('e', e);
    }
    return data;
};

export function getTreeData(list) {
	const newList = [];
	const map = {};

	list.forEach((e) => (map[e.id] = e));

	list.forEach((e) => {
		const parent = map[e.parentId];

		if (parent) {
			(parent.children || (parent.children = [])).push(e);
		} else {
			newList.push(e);
		}
	});

	const fn = (list) => {
		list.map((e) => {
			if (e.children instanceof Array) {
				e.children = orderBy(e.children, "orderNum");
				fn(e.children);
			}
		});
	};

	fn(newList);

	return orderBy(newList, "orderNum");
}

function orderBy(list, key) {
	return list.sort((a, b) => a[key] - b[key]);
}

export const getURLParameters = (url) =>
(url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
  (a, v) => ((a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a),
  {}
);
