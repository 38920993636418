// https://router.vuejs.org/zh/
import 'nprogress/nprogress.css'

import queue from '@/views/queue/index.vue'
import queueSucceed from '@/views/queue/succeed.vue'
// 定义路由，每个路由都需要映射到一个组件
export const routes = [
  {
    path: '/queue',
    name: 'queue',
    component: queue,
    meta: {
      needGuard: true,
      title: '排队',
    },
  },
  {
    path: '/queue/succeed',
    name: 'queue.succeed',
    component: queueSucceed,
    meta: {
      needGuard: true,
      title: '排队成功',
    },
  },
]

export function mergeRoutes(allRoutes: any[]) {
  allRoutes.push(...routes)
}
