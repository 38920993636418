// import { createApp } from 'vue'
// import App from '../App.vue'
// import { createI18n } from 'vue-i18n'
import i18n from "i18next";
import messages from './data/index'
// const app = createApp(App)

import LanguageDetector from "i18next-browser-languagedetector";

export const createI18n = () => {
	i18n.use(LanguageDetector).init({
		resources: {
			en: { translation: messages["en"] },
			"zh-CN": { translation: messages["zh-CN"] },
			"zh-HK": { translation: messages["zh-TW"] }, // TODO: 暫時採用台灣版本的繁體中文
			"zh-TW": { translation: messages["zh-TW"] },
			ko: { translation: messages["ko"] },
			// es: { translation: messages["es"] }
		},
		fallbackLng: "zh-CN",
		debug: true,
		react: {
			useSuspense: false
		},
		detection: {
			order: ["querystring", "localStorage", "navigator"],
			lookupQuerystring: "lang"
		}
	});

	return i18n;
};

export default createI18n;

export function set(lang: string) {
	return i18n.changeLanguage(lang);
}