// https://router.vuejs.org/zh/
import 'nprogress/nprogress.css'

import Photo3d from '@/views/photo_3d/index.vue'
import Ar from '@/views/photo_3d/ar.vue'
// 定义路由，每个路由都需要映射到一个组件
const routes = [
  {
    path: '/photo3d',
    name: 'photo3d',
    component: Photo3d,
    meta: {
      needGuard: true,
      title: '3d相册',
    },
    
  },
  {
    path: '/ar',
    name: 'ar',
    component: Ar,
    meta: {
      needGuard: true,
      title: 'ar',
    },
    
    
  },
]

export function mergeRoutes(allRoutes: any[]) {
  allRoutes.push(...routes)
}
