<script lang="tsx" setup>
import useClipboard from 'vue-clipboard3'
const { toClipboard } = useClipboard()
const router = useRouter()
import { orderStatus } from '@/utils/utils'
import { closeToast, showFailToast, showSuccessToast, showToast } from 'vant';
import * as myOrderApi from '@/api/my_order'
import { useStore } from '@/stores'
const store = useStore();
import { useTokenStore } from '@/stores/token'
const tokenStore = useTokenStore();

const {isWechat} = tokenStore;

const orderInfo = ref({})
const orderList = ref("")
const orderId = ref("")
const title = ref("")
const pid = ref("")
const userInfo = ref({})
const nums = ref(0)
const orderDetailInfo = ref({})
const dialogShow = ref(false)
const dialogShow2 = ref(false)
const actionShow = ref(false)
const actions = ref([
  {
    name: '删除订单'.$t,
  },
])

onMounted(()=> {
  userInfo.value = tokenStore.userInfo;
  orderInfo.value = store.orderInfo;
  console.log("orderInfo.value", orderInfo.value)
  getOrderInfo(orderInfo.value.pid);
})
const returnClick = () => history.back();
//  更多点击
const moreClick = () => {
    actionShow.value = !actionShow.value
}

// 删除点击
const onSelect = (e) => {
    if(e.detail.name) {
        dialogShow.value =  true
    }
}
const higher = computed(()=> {
  return isWechat() ? 'higher' : '';
});

// 删除确认
const tapDialogButton = (e) => {
    // const u = wx.getStorageSync('userInfo');
    let params = {
        pid: orderInfo.value.pid,
        // unionid: u.unionid
    }
    // 状态判断
    let status = orderInfo.value.status
    let digital_order_id = orderInfo.value.digital_order_id;
    let order_id = orderInfo.value.order_id;
    let experience_status = orderInfo.value.remaining_experience.status;
    let buyType = 0;
    let undone_status = 0;
    if(digital_order_id != order_id && digital_order_id && order_id) {
        buyType = 1; // 相册和手办都购买
        if(status != 9000) {
            undone_status = 1;
        }
    }else if(digital_order_id == order_id) {
        buyType = 2; // 只购买相册
        if(status < 7011) {
            undone_status = 1;
        }
    }else if(digital_order_id == 0 && order_id !=0) {
        buyType = 3; // 只购买手办
        if(status != 9000) {
            undone_status = 1;
        }
    }else if(experience_status == 1 && status < 4100) {
        buyType = 4; // 免费体验3d相册中
        if(status < 4100) {
            undone_status = 1;
        }
    }
    if(undone_status!=0) {
      dialogShow.value = false;
      // dialogShow3.value = true;
        return
    }
    // app.api.deleteOrder(params).then((res)=> {
    //     if(res.code == 1000) {
    //         showToast("删除成功".$t)
    //         setTimeout(()=> {
    //             router.push({
    //                 path: "/myOrder",
    //             })
    //         }, 2000)
    //         dialogShow.value = false;
    //     }else if(res.code == -1) {
    //         showFailToast(res.message)
    //     }
    // }).catch((err)=> {
    //   title.value = err;
    //   dialogShow.value = false;
    //   dialogShow2.value = true;
    // })
    closeToast();    
    
}

const tapDialogButton2 = () => {
  dialogShow2.value = false
  contact();
}

const onClose = () => {
  actionShow.value = false
}

//获取订单的详情信息
const getOrderInfo = (pid) => {
    if (pid) {
        myOrderApi.detail(pid).then((res:any)=> {
          let data = res;
          data.forEach(element=> {
              let arr = element.model_size.split(" ")
              let obj = [];
              arr.forEach(item => {
                  let index = item.lastIndexOf("*");
                  let num = item.substring(index+1, item.length)
                  let size = item.substring(0, index)
                  obj.push({size,num})
              });
              element.filterSize = obj
          })
          orderList.value = data;
        })  
        //     data = Object.assign(data, obj)
    }
}

// 复制
const copyOrderId = () => {
  copy(orderInfo.value.pid.toString())
}

// 使用插件
const copy = async (msg) => {
  try {
    // 复制
    console.log("msg", msg)
    await toClipboard(msg)
    showSuccessToast("复制成功".$t)
    // 复制成功
  } catch (e) {
    // 复制失败
    showFailToast("复制失败".$t)
  }
}
const callShow = ref(false);
const callClick = () => {
  callShow.value = true;
}

const contact = () => {
  const a = document.createElement("a");
  a.href = "tel:" + orderInfo.value.shop_tel
  a.click();
  callShow.value = false;
}

// 跳转至3D相册
// toAlbum(e) {
//   //获取用户的信息
//   const u = wx.getStorageSync('userInfo');
//   var pid = this.data.orderInfo.pid;
//   var oid = this.data.orderInfo.order_id;
//   var cn = this.data.orderInfo.customer_name;
//   var cid = this.data.orderInfo.customer_id;
//   var createTime = this.data.orderInfo.create_time;
//   var status = this.data.orderInfo.status;
//   var remainingExperienceStatus = this.data.orderInfo.remaining_experience.status;
//   var expireTime = this.data.orderInfo.remaining_experience.day;
//   var digitalOrderId = this.data.orderInfo.digital_order_id;
//   var digitalType = this.data.orderInfo.digital_type;
//   var titleCode = this.data.orderInfo.remaining_experience.title_code;
//     //判断createTime时间是否在2023-04-15 00:00:00 之后
//     wx.request({
//         url: config.url + '/miniProgram/judgeP3dLogOrderTime',
//         data: { photoTime: createTime, pid },
//         header: { 'content-type': 'application/json'  },
//         success: (res) => {
//             if(res.data.data.use_status!=1){
//                 wx.showToast({
//                     title: '该订单不支持3D相册，需重新拍照',
//                     icon: 'none',
//                     duration: 2000
//                 })
//                 return
//             }
//             const url = `/package3D/pages/3d/3d?openid=${u.openid}`
//             + `&unionid=${u.unionid}&id=${pid}&oid=${oid}`
//             + `&cn=${cn}&cid=${cid}&expireTime=${expireTime}`
//               + `&digitalType=${digitalType}`
//               + `&status=${status}`
//               + `&titleCode=${titleCode}`
//             + `&remainingExperienceStatus=${remainingExperienceStatus}`;
//             //判断是否有购买3D相册
//             if (digitalOrderId == 0) {
//                 wx.navigateTo({
//                     url: url
//                 });
//                 return
//             }

//             if(remainingExperienceStatus == 1) {
//                 if(status < 4100){
//                     wx.showToast({
//                         title: '3D相册制作中',
//                         icon: 'none',
//                         duration: 2000
//                     })
//                     return
//                 }
//                 wx.navigateTo({
//                     url: url,
//                 });
//             }
//             wx.navigateTo({
//                 // 跳转到webview页面
//                 // url: `/pages/webview/webview?nav=${navtitle}&url=${encodeURIComponent(url)}`,
//                 url: url,
//             });
//         }
//     })
  
// },

//前往物流界面
const turnToDelivery = () => {
    if(orderInfo.value.delivery_no) {
        // wx.navigateTo({
        //     url: '/pages/delivery/delivery',
        //     success: function (res) {
        //         res.eventChannel.emit("orderInfo", that.data.orderInfo)
        //     }
        // })
    }else {
        showToast("暂无快递信息".$t)
    }
    
}
</script>

<template>
  <van-dialog :title="'确认删除订单？'.$t" :message="'删除的订单将无法恢复'.$t" v-model:show="dialogShow" :confirmButtonText="'确认删除'.$t"
    :cancelButtonText="'我再想想'.$t" @confirm="tapDialogButton" showCancelButton confirm-button-color="#AAAAAA"
    cancel-button-color="#009944" round />
  <van-dialog :title="'门店电话'.$t" :message="'确定拨打门店电话吗？'.$t" v-model:show="callShow" :confirmButtonText="'确定'.$t" :cancelButtonText="'取消'.$t"
    @confirm="contact" showCancelButton confirm-button-color="#AAAAAA" cancel-button-color="#009944" round />
  <van-dialog :title="title" v-model:show="dialogShow2" :confirmButtonText="'联系门店'.$t" :cancelButtonText="'我再想想'.$t"
    @confirm="tapDialogButton2" showCancelButton confirm-button-color="#AAAAAA" cancel-button-color="#009944" round />
  <!-- <van-dialog :title="'订单处于未完结状态'.$t，无法删除" v-model:show="dialogShow3" :confirmButtonText="'我已知晓'.$t" @confirm="tapDialogButton3"
    confirm-button-color="#009944" round /> -->
  <van-action-sheet round v-model:show="actionShow" :actions="actions" @close="onClose" @select="onSelect" @cancel="onClose"
    :cancel-text="'取消'.$t" />
  <van-nav-bar v-if="!isWechat()" :title="store.navTitle" :left-text="'返回'.$t" left-arrow
  @click-left="returnClick" safe-area-inset-top/>
  <div class="page">
   <div :class="['container', higher]">
    <div class="order_info">
      <div class="text-wrapper_43">
        <div class="order-info">
          <div class="text_31">{{ '订单信息'.$t }}</div>
          <img src="@/assets/order/more.png" mode="widthFix" class="more" @click="moreClick"/>
        </div>
      </div>
      <div class="text-wrapper_45">
        <div class="order-id">
          <div class="text_39">{{'订单ID'.$t}}</div>
          <div class="text_40">{{orderInfo.pid}}</div>
        </div>
        <div @click="copyOrderId()" class="button_2">{{ '复制'.$t }}</div>
      </div>
      <div class="text-wrapper_44">
        <div class="text_39">{{ '拍摄门店'.$t }}</div>
        <div class="text_40">{{orderInfo.shop_name}}</div>
      </div>
      <div class="text-wrapper_44">
        <div class="text_39">{{ '拍摄时间'.$t }}</div>
        <div decode="true" class="text_40">{{orderInfo.takephoto_time}}</div>
      </div>

      <!-- 分割线 -->
      <div class="line"></div>

      <div class="group_13" @click="callClick">
        <div class="image-text_12">
          <div class="icon_2">
            <img class="icon_4" src="@/assets/order/tel.png" />
          </div>
          <div  class="text-order_record">{{ '联系门店'.$t }}</div>
        </div>
      </div>
    </div>

    <div class="order_record">

      <!-- 订单明细数据 -->
      <div class="section_2">
        <div class="order-title">
          <div class="title">{{ '订单记录'.$t }}</div>
        </div>
        <block v-for="item, index in orderList" :key="index">
          <div class="order-box">
            <div class="order-top">
              <div class="header-text">
                <div lines="1" class="order-name" v-if="item.digital_type == 0">{{ '手办'.$t }}</div>
                <div lines="1" class="order-name" v-else-if="item.digital_type == 4">{{'体验3D相册'.$t}}</div>
                <div lines="1" class="order-name" v-else-if="item.digital_type == 1">{{'3D相册'.$t}}</div>
                <div class="order-status" v-if="item.print_type == 2 && item.digital_type == 0">{{ '重打'.$t }}</div>
                <div class="order-status" v-else-if="item.print_type == 3 && item.digital_type == 0">{{ '加打'.$t }}</div>
              </div>
              <div  class="text_13">{{item.create_time}}</div>
            </div>

            <!-- 遍历数组 -->
            <div class="" v-if="item.digital_type == 0">
              <div class="text-wrapper_37" v-for="fItem, fIndex in item.filterSize" :key="fIndex" v-for-item="fItem">
                  <div class="text_14">{{fItem.size}}</div>
                  <div class="text_15">x{{fItem.num}}</div>
              </div>
            </div>

            <div class="text-wrapper_37">
              <div class="text_38">
                {{orderStatus(item.status,item.digital_type).$t}}
                <van-icon v-if="item.status>=9000 && item.digital_type == 0" @click="turnToDelivery" data-delivery_no="{{item.delivery_no}}" name="arrow" size="18px" color="#767676"/>
              </div>
              <div class="text_38">{{item.update_time}}</div>
            </div>
            
            <div class="text-wrapper_37">
              <div  class="text_38">{{ '费用合计'.$t }}</div>
              <div class="total">
                <div class="text_29">￥</div>
                <div class="text_30">{{ item.goods_amount / 100}}</div>
              </div>
            </div>
          </div>
        </block>
      </div>
    </div>
   </div>
  </div>
</template>

<style lang="less" scoped>
.page {
  position: relative;
  width: 375px;
  height: 100vh;
  display: flex;
  background: rgba(245, 245, 245, 1.000000);
  flex-direction: column;
  .container {
    position: relative;
    width: 100%;
    height: auto;
    background: rgba(245, 245, 245, 1.000000);
    padding-bottom: 60px;
    box-sizing: border-box;
    .order_info {
      background-color: rgba(255, 255, 255, 1.000000);
      border-radius: 8px;
      width: 345px;
      display: flex;
      flex-direction: column;
      margin: 7.5px auto 0 auto;
    }

    .order_record {
      background-color: rgba(255, 255, 255, 1.000000);
      border-radius: 8px;
      width: 345px;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      margin: 15px auto;

      .section_2 {
        position: relative;
        width: 315px;
        height: auto;
        margin: 0 auto;
        border-radius: 8px;

        .order-title {
          display: flex;
          align-items: center;
          width: 100%;
          height: 24px;
          margin-top: 24px;
          padding-bottom: 6px;
          margin-bottom: 11px;
          border-bottom: 2px solid #F0F0F0;

          .title {
            color: #333333;
            font-size: 16px;
            font-family: Source Han Sans CN-Bold;
            font-weight: 700;
          }
        }

        .order-box {
          display: flex;
          flex-direction: column;
          position: relative;
          width: 100%;
          margin-bottom: 15px;
          background: #FAFDFB;
          .order-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: rgba(236, 244, 234, 1.000000);
            border-radius: 10px 10px 0px 0px;
            width: 315px;
            height: 44px;
            margin: 0 auto;
            padding: 0 15px;
            box-sizing: border-box;

            .header-text {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              position: relative;
              height: 100%;

              .order-name {
                overflow-wrap: break-word;
                color: #1A1A1A;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium;
                font-weight: 500;
                text-align: left;
                white-space: nowrap;
                line-height: 14px;
                margin-right: 8px;
              }

              .order-status {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 30px;
                height: 24px;
                background: #ffffff;
                font-size: 11px;
                color: #E6A23C;
                font-weight: 500;
                border-radius: 4px;
              }
            }

            .text_13 {
              height: 14px;
              overflow-wrap: break-word;
              color: #767676;
              font-size: 14px;
              font-family: Source Han Sans CN-Medium;
              font-weight: 500;
              text-align: left;
              white-space: nowrap;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  .higher {
    padding-bottom: 110px;
  }
}



.text-group_9 {
  width: 234px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.text-wrapper_37 {
  width: 285px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FAFDFB;
  border-bottom: 2px solid #F0F0F0;
  margin: 0 auto;

  &:last-child {
    border-bottom: none;
  }


  .total {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    .text_29 {
      width: 12px;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1.000000);
      font-size: 12px;
      font-family: Source Han Sans CN-Bold;
      font-weight: 700;
      text-align: right;
      white-space: nowrap;
      line-height: 12px;
    }

    .text_30 {
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1.000000);
      font-size: 16px;
      font-family: Source Han Sans CN-Bold;
      font-weight: 700;
      text-align: left;
      white-space: nowrap;
      line-height: 16px;
    }
  }

  .button_3 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background-color: #009944;
    border-radius: 6px;
    font-size: 14px;
    margin: 0;

    &::after {
      display: none;
    }
  }
}

.line {
  margin: 0 auto;
  width: 95%;
  height: 1px;
  background-color: #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
}

.text_4 {
  width: 48px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1.000000);
  font-size: 16px;
  font-family: Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
}
.text_14 {
  height: 12px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1.000000);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text_15 {
  height: 12px;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1.000000);
  font-size: 12px;
  font-family: Source Han Sans CN-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 12px;
}

.text-wrapper_43 {
  position: relative;
  width: 315px;
  height: 24px;
  margin: 14px auto 11px auto;
  padding-bottom: 6px;
  border-bottom: 1px solid #F0F0F0;

  .order-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 315px;
    height: 100%;
    margin: 0 auto;

    .more {
      position: relative;
      width: 16px;
      height: 16px;
    }
  }
}

.text_31 {
  width: 64px;
  height: 16px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1.000000);
  font-size: 16px;
  font-family: Source Han Sans CN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
}

.text-wrapper_44 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 315px;
  height: 20px;
  margin: 0 auto 10px auto;
}

.text_38 {
  display: flex;
  align-items: center;
  color: #1A1A1A;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium;
  font-weight: 500;
  white-space: nowrap;
}

.text-wrapper_45 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 315px;
  margin: 0 auto 10px auto;

  .order-id {
    display: flex;
  }
}

.text_39 {
  // width: 52px;
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1.000000);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-right: 15px;
}

.text_40 {
  height: 15px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1.000000);
  font-size: 14px;
  font-family: Source Han Sans CN-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 15px;
  margin-top: 2px;
}

.button_2 {
  width: 36px;
  height: 20px;
  color: #AAAAAA;
  border: 1px solid #AAAAAA;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  margin-left: 10px;
}

.button_2::after {
  display: none;
}


.group_13 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
}

.image-text_12 {
  height: 16px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.icon_2 {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .icon_4 {
    width: 14px;
    height: 14px;
  }
}

.text-order_record {
  height: 16px;
  overflow-wrap: break-word;
  color: #009944;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 16px;
}

.group_9 {
  background-color: rgba(255, 255, 255, 1.000000);
  height: 72px;
  display: flex;
  flex-direction: column;
  width: 375px;
}

</style>
