import defaultHeadimgUrl from '@/assets/common/avatar.png'
import { useTokenStore } from '@/stores/token'
import hello from 'hellojs'

export function headimgurl() {
    const tokenStore = useTokenStore();
    const user = tokenStore.userInfo;
    if (user?.headimgurl) {
        return user?.headimgurl;
    }
    return defaultHeadimgUrl;
}

export function nickname() {
    const tokenStore = useTokenStore();
    const user = tokenStore.userInfo;
    console.log("tokenStore.userInfo", tokenStore.userInfo)
    if (user.nickname) {
        return user.nickname;
    } else {
        return user.social_type + user.id;
    }
}

export function logout() {
    const tokenStore = useTokenStore();
    const user = tokenStore.userInfo;
    const socialType = user.social_type;
    if (!socialType) {
        return Promise.reject('未知的登录方式');
    }

    return hello(socialType).logout();
}