<script lang="tsx" setup>
import { useRoute } from 'vue-router'
import type { Ref } from 'vue'
import { modelSize as _modelSize, printType } from './utils'
import { orderInfo as orderInfoApi } from '@/api/short_url'
import Loading from '@/components/loading/index.vue'

import { filterMsgButton } from '@/dict/status_dict'

const code = ref('')
const src = ref('')
const data: Ref<any> = ref({})
const tags = ref([])
const loading = ref(false)

const modelSize = _modelSize

const route = useRoute()
onMounted(() => {
  code.value = route.query.code as string
  getInfo()
})

function getImage() {
  src.value = ''
  if (data.value.info && data.value.info.texture_cover_img)
    src.value = `https://3dview.suwa3d.com/${data.value.info.texture_cover_img}`
}

function getTags() {
  // 判断是否有订单信息
  if (!data.value.info || !data.value.info.pid)
    return

  // 遍历循环
  const arrTags = [];
  // 数据信息遍历
  [data.value].forEach((item: any) => {
    const arrTag = []
    // 加急单
    if (item.is_expedited) {
      // 插入数据
      arrTag.push('加急')
    }

    // 超时单
    if (item.is_expires)
      arrTag.push('超时')

    const msg = printType(item.info.print_type)
    if (msg !== '' && msg !== '正常')
      arrTag.push(msg)

    arrTags.push(arrTag)
  })
  tags.value = arrTags
}

function isSample() {
  return data.value.order_tag_type === 'sample'
}

function getPrintTypeText() {
  switch (data.value?.info?.print_type) {
    case 1:
      return '首次打印'
    case 2:
      return '重打'
    case 3:
      return '加打'
  }
}

function getStatusText() {
  return filterMsgButton(data.value?.info?.status, 'text')
}

function getInfo() {
  loading.value = true
  orderInfoApi(code.value).then((res: any) => {
    data.value = res || {}
    getImage()
    getTags()
  }).finally(() => {
    loading.value = false
  })
}
</script>

<template>
  <van-cell-group title="基本信息">
    <van-cell title="拍照订单">
      <span>
        {{ data?.info?.pid }}
      </span>
    </van-cell>
    <van-cell title="尺寸*数量">
      <van-tag v-for="(item, key) in modelSize(data?.info?.model_size)" :key="key" type="success" plain>
        {{ item }}
      </van-tag>
    </van-cell>
    <van-cell title="提交建模时间" :value="data.submit_model_time" />
    <van-cell title="订单状态">
      <van-tag type="success">
        {{ loading ? '' : getStatusText() }}
      </van-tag>
    </van-cell>
    <van-cell title="打印类型">
      <van-tag v-if="isSample()" type="success">
        样品
      </van-tag>
      <van-tag type="success">
        {{ getPrintTypeText() }}
      </van-tag>
    </van-cell>
  </van-cell-group>

  <van-cell-group title=" ">
    <van-cell title="商品金额">
      <span>{{ data.goods_amount / 100 }} {{ data.currency }}</span>
    </van-cell>
    <van-cell v-if="data.other_fee_amount > 0 && data.other_fee_name !== ''" :title="`优惠金额(${data.other_fee_name})`">
      <span>{{ data.other_fee_amount / 100 }} {{ data.currency }}</span>
    </van-cell>
    <van-cell title="总计">
      <span>{{ data.total_amount / 100 }} {{ data.currency }}</span>
    </van-cell>
  </van-cell-group>

  <van-cell-group title="门店客户信息">
    <van-cell title="门店" :value="data?.info?.shop_name" />
    <van-cell title="客户">
      {{ data?.info?.customer_name }} (TEL:{{ data?.info?.customer_mobile }})
    </van-cell>
    <van-cell title="寄送地址">
      <span>
        {{ data?.info?.province_name }}
        {{ data?.info?.city_name }}
        {{ data?.info?.country_name }}
        {{ data?.info?.address }}
      </span>
    </van-cell>
  </van-cell-group>

  <van-cell-group title="重量">
    <van-cell title="毛重">
      <span>{{ data?.info?.model_gross_weight }}g</span>
    </van-cell>
    <van-cell title="净重">
      <span>{{ data?.info?.model_weight }}g</span>
    </van-cell>
  </van-cell-group>
  <van-cell-group v-if="data.motherList && data.motherList.length > 0" title="其他">
    <van-cell title="子母单">
      <van-tag
        v-for="(item, key) in data.motherList" :key="key" type="success"
        class="mother-tag"
        plain
      >
        {{ item.pid }}
      </van-tag>
    </van-cell>
  </van-cell-group>
  <Loading :loading="loading" />
</template>

<style lang="less" scoped>
.mother-tag{
    margin-right: 0.4rem;
}
</style>
