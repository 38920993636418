import request from '@/utils/request'

export const glb = (id: number, extParams?: any) => {
    return request('model/glb', {
        method: 'GET',
        params: Object.assign({}, {
            id,
            type: 1,
            without_head: 1,
        }, extParams),
    })
}

export const get3dBgList = (pid: number) => {
    return request('material/bg', {
        method: 'GET',
        params: {
            pid,
        },
    })
}
