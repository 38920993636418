import en from './en'
import cn from './zh-CN'
import tw from './zh-TW'
import ko from './ko'
 
export default {
  'zh-CN': cn,
  'zh-TW': tw,
  'zh-HK': tw,
  'en': en,
  'ko': ko
}