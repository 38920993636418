import { defineStore } from 'pinia'
import { STORAGE_TOKEN_KEY, TOKEN } from './mutation-type'
import localStorage from '@/utils/local-storage'
import {parseJwt} from "@/utils/utils"

export const useTokenStore = defineStore(TOKEN, () => {
  const token = ref(localStorage.get(STORAGE_TOKEN_KEY) || '')
  let userInfo = ref(parseJwt(token.value))
  function login(val: string) {
    localStorage.set(STORAGE_TOKEN_KEY, val)
    userInfo.value = parseJwt(val);
    token.value = val
  }

  function logout() {
    localStorage.remove(STORAGE_TOKEN_KEY)
    token.value = ''
  }

  function isLoggedIn(): boolean {
    return !!token.value
  }

  function isWechat(): boolean {
    return !!userInfo.value?.isWechat
  }

  return {
    token,
    userInfo,
    login,
    logout,
    isLoggedIn,
    isWechat,
  }
})
