import request from '@/utils/request'

// 参数为 code
export const actions = (code: string) => {
  return request('/shortUrl/actions', { params: { code }, method: 'GET' })
}

// 参数为 code, 订单履历
export const orderTrack = (code: string) => {
  return request('/shortUrl/orderTrack', { params: { code }, method: 'GET' })
}

// 参数为 code, 订单信息
export const orderInfo = (code: string) => {
  return request('/shortUrl/orderInfo', { params: { code }, method: 'GET' })
}

// 参数为 code, 打印信息
export const printOrderInfo = (code: string) => {
  return request('/shortUrl/printOrderInfo', { params: { code }, method: 'GET' })
}

