import {
//   AllStatus,
  ModelExpressingStatus,
  ModelPrintedStatus,
  ModelPrintedStatusWithFilledNetWeight,
  ModelPrintingStatus,
  //   ModelRepairedStatus,
  ModelRepairingStatus,
  ModelingStatus,
  PhotoAlbum3DCreatedStatus,
  WaitingMakeModelStatus,
  WaitingModelRepairStatus,
  WaitingPrintStatus,
  WaitingPushMakeModelStatus,
} from './status_code'
// 状态
export const Dict = [
  {
    label: '未提交',
    value: WaitingPushMakeModelStatus,
    type: 'warning',
    lang_key: 'order_status_modeling_to_be_submitted_by_the_store',
  },
  {
    label: '待建模',
    value: WaitingMakeModelStatus,
    type: 'primary',
    lang_key: 'order_status_to_be_modeled',
  },
  {
    label: '建模中',
    value: ModelingStatus,
    type: 'warning',
    lang_key: 'common_modeling',
  },
  {
    label: '建模完成',
    value: 4100,
    type: 'warning',
    lang_key: 'modeling_completed',
  },
  {
    label: '待修模',
    value: WaitingModelRepairStatus,
    type: 'primary',
    lang_key: 'order_status_to_be_repaired',
  },
  {
    label: '修模中',
    value: ModelRepairingStatus,
    type: 'danger',
    lang_key: 'order_status_modeling',
  },
  {
    label: '3D相册已创建',
    value: PhotoAlbum3DCreatedStatus,
    type: 'danger',
    lang_key: 'model_status_3d_photo_album_created',
  },
  {
    label: '待打印',
    value: WaitingPrintStatus,
    type: 'primary',
    lang_key: 'order_status_to_be_printed',
  },
  {
    label: '打印中',
    value: ModelPrintingStatus,
    type: 'primary',
    lang_key: 'order_status_printing',
  },
  {
    label: '后道工序处理',
    value: ModelPrintedStatus,
    type: 'primary',
    lang_key: 'printed_processing',
  },
  {
    label: '称净重',
    value: ModelPrintedStatusWithFilledNetWeight,
    type: 'primary',
    lang_key: 'common_printing_completed_(net_weight_weighed)',
  },
  {
    label: '快递中',
    value: ModelExpressingStatus,
    type: 'primary',
    lang_key: 'order_status_express_delivery',
  },
]

export const contractDict = [
	{
		label: "用户服务协议",
		value: 1,
	},
	{
		label: "用户隐私政策",
		value: 2,
	},
	{
		label: "儿童隐私政策",
		value: 3,
	},
]

export const ExpectShop = 130 || 140;

export const contractMap = contractDict.reduce((carr, curr) => {
	carr[curr.value] = curr.label;
	return carr
}, {});

export function filterMsgButton(status: number, flag: any) {
  const found = Dict.find((el) => {
    return el.value === status
  })

  if (flag === 'text') {
    if (found)
      return found.label

    return '状态错误'
  } else {
    if (found)
      return found.type

    return 'info'
  }
}
