<script setup lang="tsx">
import { useRoute } from 'vue-router'
import router from '@/router'
const index = ref('0')

const route = useRoute()
onMounted(() => {
  index.value = route.query.index as string
})

function goBack() {
  router.back()
}
</script>

<template>
  <div class="container">
    <van-empty :description="'排队成功'.$t" image="/resource/img/succeed.png">
      <div class="footer">
        <p class="text">
          {{"您前面还有".$t}} <span class="num">{{ index }}</span> {{"人请等待叫号".$t}}
        </p>
        <van-button @click="goBack">
          {{"返回".$t}}
        </van-button>
      </div>
    </van-empty>
    <div class="empty" />
  </div>
</template>

<style scoped lang="less">
.container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  .text{
    font-size: 14px;
    color: #aaaaaa;
  }
  .num{
    color: #009944;
  }
}

.empty{
  height: 10vh;
}
</style>
