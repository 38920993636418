export const View3DPhotoAlbumCode = 'view_3d_photo_album'
export const View3DPhotoAlbumName = '查看3D相册'
export const View3DPhotoAlbumId = 1

// 运营网址
export const OperationWebsiteCode = 'operation_website'
export const OperationWebsiteName = '运营网址'
export const OperationWebsiteId = 2

// 运营小程序
export const OperationMiniProgramCode = 'operation_mini_program'
export const OperationMiniProgramName = '运营小程序'
export const OperationMiniProgramId = 3

// 订单履历
export const OrderHistoryCode = 'order_history'
export const OrderHistoryName = '订单履历'
export const OrderHistoryId = 4

// 订单信息
export const OrderInfoCode = 'order_info'
export const OrderInfoName = '订单信息'
export const OrderInfoId = 5

// 打印订单信息
export const PrintOrderInfoCode = 'print_order_info'
export const PrintOrderInfoName = '打印订单信息'
export const PrintOrderInfoId = 6
