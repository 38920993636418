import request from '@/utils/request'

// 参数为 page, size, pid
export const page = (params: any) => {
  return request('/myOrder/page', { params, method: 'GET' })
}

// 主要参数为pid
export const detail = (pid: number) => {
  return request('/myOrder/detail', { params: { pid }, method: 'GET' })
}

