import { defineStore } from 'pinia'
export const useStore = defineStore("index", {
  state: () => {
    return {
      orderInfo: ref({}),
      navTitle: ref(""),
      brandInfo: ref({}),
      redirect: ""
    }
  },
  actions: {
    updateOrderInfo(data) {
      this.orderInfo = data;
    },
    setBrandInfo(data) {
      this.brandInfo = data;
    },
    updateTitle(data) {
      this.navTitle = data
    },
    setRedirect(path: string) {
      this.redirect = path
    },
    redirectUrl() {
      return this.redirect
    }
  },
  persist:true //store数据持久化
})

