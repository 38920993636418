<template>
    <van-nav-bar v-if="!isWechat()" :title="store.navTitle" left-text="返回" left-arrow
  @click-left="returnClick" safe-area-inset-top/>
    <div class="page">
        <van-dialog
            :title="'确认退出登录？'.$t"
            v-model:show="dialogShow"
            :confirmButtonText="'确认退出'.$t"
            :cancelButtonText="'我再想想'.$t"
            @confirm="onLogout"
            showCancelButton
            confirm-button-color="#AAAAAA"
            cancel-button-color="#009944"
            round
        />
        <div class="container">
            <div class="person-box">
                <div class="column">
                    <div class="text">{{'头像'.$t}}</div>
                    <div class="avatar-box">
                        <img :src="headimgUrl" class="avatar"/>
                    </div>
                </div>
                <div class="column">
                    <div class="text">{{'账号'.$t}}</div>
                    <div class="nickname">{{ nickname }}</div>
                    <!-- <div class="input_box">
                        <input type="nickname" class="nickname" :value="userInfo.nickname"/>
                    </div> -->
                </div>
                <!-- <div class="column">
                    <div class="text">{{'性别'.$t}}</div>
                    <div class="sex">{{sex}}</div>
                </div> -->
            </div>
            <div class="logout">
                <div class="logout-btn" @click="showLogout">{{'退出登录'.$t}}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useStore } from '@/stores';
import { useTokenStore } from '@/stores/token';
import { closeToast, showLoadingToast, showToast } from 'vant';
import {headimgurl, logout, nickname as fNickname} from './utils';

const store = useStore()
const tokenStore = useTokenStore()
const router = useRouter()
const {isWechat} = tokenStore;

const userInfo = ref("")
const dialogShow = ref(false)
const sex = ref("")

const headimgUrl = computed(() => {
    return headimgurl();
});

const nickname = computed(() => {
    return fNickname();
})

onMounted(()=> {
    userInfo.value = tokenStore.userInfo;
    let sexId = userInfo.value.sex;
    switch(sexId) {
        case 1: sex.value = "男";break;
        case 2: sex.value = "女";break;
        default: sex.value = "未知";break;
    }
})

const returnClick = () => history.back();
// 点击是否要退出登录
const showLogout = () => {
    dialogShow.value =  true
}
// 退出登录
const onLogout = () => {
    showLoadingToast({message: "退出登录中...".$t, forbidClick: true,});
    setTimeout(()=> {
        // TODO: 退出社会化登录
        tokenStore.logout();
        logout();
        // wx.removeStorage({
        //     key: 'authorization',
        // })
        router.push({
            path: '/myOrder',
        })
        closeToast();
    }, 1500)   
}
</script>

<style lang="less" scoped>
.page {
    width: 100vw;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #F5F5F5;
    /* 利用新增的 env() 和 constant() 特性，自动计算底部安全距离 */
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    /* 可计算 */
    // padding-bottom: calc(124px + env(safe-area-inset-bottom));
}
.container {
    width: 100%;
    padding: 15px 0;
    height: auto;
    .person-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 345px;
        height: 175px;
        margin: 0 auto;
        padding: 12px 0;
        background: #ffffff;
        border-radius: 8px;
        .column {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            width: 100%;
            height: 56px;
            padding: 9px 16px;
            box-sizing: border-box;
            .text {
                color: #767676;
                font-size: 16px;
                font-weight: 400;
                font-family: Source Han Sans CN-Regular;
            }
            .avatar-box {
                position: relative;
                width: 38px;
                height: 38px;
                border-radius: 16px;
                overflow: hidden;
                margin: 0;
                padding: 0;
                .avatar {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
            .input_box {
                display: flex;
                align-items: center;
                min-width: 90px;
                .nickname {
                    text-align: right;
                    appearance: none;
                    border: none;
                }
            }
        }
    }
    .logout {
        position: absolute;
        bottom: 0%;
        left: 50%;
        width: 345px;
        height: 192px;
        margin-left: -172.5px;
        .logout-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 42px;
            background: #ffffff;
            color: #F56C6C;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular;
            font-weight: 400;
            border-radius: 8px;
        }
    }
}
</style>