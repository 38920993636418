function googleToNormalize(data: any) {
  return {
    unionid: data.id,
    social_type: 'google',
    nickname: (data.last_name + data.first_name) || '',
    email: data.email || '',
    headimgurl: data.thumbnail || '',
  }
}

function twitterToNormalize(data: any) {
  return {
    unionid: data.id_str || data.id,
    social_type: 'twitter',
    nickname: data.name || '',
    email: data.email || '',
    headimgurl: data.thumbnail || '',
  }
}

export function normalize(socialType: string, data: any) {
  switch (socialType) {
    case 'google':
      return googleToNormalize(data)
    case 'twitter':
      return twitterToNormalize(data)
  }
}

