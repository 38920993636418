const zhCN = {
    user: {
        "language": "语言",
    },
    "store_name":"门店",
    "type_id_2":"手办尺寸",
    "type_id_3":"拍摄姿势",
    "type_id_4":"额外携带",
    "type_id_5":"额外携带",
    "total_price":"价格",
    "total":"合计",
    "submit_tip":"点击登录提交等待排队",
    "mobile_form_tip":"或填写手机姓名登记",
    "title":"拍照排队",
    "err_tip_page":"链接参数错误，无法获取到门店信息",
    "pl_chose_goods":"请先选择手办尺寸",
    "pl_chose_attr":"请先选择拍摄姿势",
    "success_queue":"排队成功",
    "fail_queue":"排队失败",
    "email": "邮箱",
    "mobile": "手机",
    "InternationalTelephoneAreaCode": "国际电话区号",
    "name": "姓名",
    "submit": "提交",
    "mobile_empty": "手机号不能为空",
    "name_empty": "姓名不能为空",
    "email_empty": "邮箱不能为空",
    "tip": "以上为单人拍照参考价格，详细可与店员咨询",
    "user_service_agreement": "用户服务协议",
    "user_privacy_agreement": "用户隐私政策",
    "agree_tip": "请阅读并同意协议",
    "your_queue_number_is": "您的排队序号是",
    "Please_wait_for_the_number_to_be_called": "请等待叫号",
    "Number": "号",
    "go_back": "返回",
    "user_phone_is_empty": "手机号不能为空",
    "store_is_empty": "门店不能为空",
    "calculate_total_amount_error": "计算总金额错误",
    "create_user_error": "创建用户错误",
    "You_have_registered_please_wait": "您已经登记过了，请等待",
    "server_error": "服务器开小差了，请稍后再试",
    "name_required": "名称是必填项",
    "mobile_required": "手机是必填项",
    "站姿": "站姿",
    "宠物+站姿": "宠物+站姿",
    "弯腰/半蹲/坐椅": "弯腰/半蹲/坐椅",
    "全蹲/坐地": "全蹲/坐地",
    "宠物": "宠物",
    "道具": "道具",
    "suwa_user_service_agreement": "用户服务协议",
    "suwa_privacy_policy": "隐私政策",
    "suwa_child_privacy_policy": "儿童隐私政策",
    "agree_and_continue": "同意并继续",
    "agree_text": "我同意",
    "privacy_hint_one": "无论数据在何处进行处理和存储，我们将始终按照隐私政策及用户协议收集、处理您的数据。",
    "privacy_hint_two": "遵照中国大陆隐私保护法律的要求，需要你签署本知情同意书。",
    "agreement_and_policy": "用户协议及隐私政策",
    'loading': '加载中',
    '加载中...': '加载中...',
    "agree_tip_prefix": "我已阅读并同意",
    "queue_buy_type": "购买类型",
    "queue_price_tip": "以上为单人拍照参考价格，详细可与店员咨询",
    "birthday": "生日",
    "手办":"手办",
    "3D相册":"3D相册",
    "体验3D相册":"体验3D相册",
    "元": "元",
    "合计：": "合计：",
    "您前面还有": "您前面还有",
    "人请等待叫号": "人请等待叫号",
    "返回": "返回",
    "排队成功": "排队成功",
    "3D摄影": "3D摄影",
    "排队": "排队",
    "登录": "登录",
    "客户手机号和客户编号不能同时为空": "客户手机号和客户编号不能同时为空",
    "详情": "详情",
    "建模完成": "建模完成",
    "人": "人",
    "3D相册制作中": "3D相册制作中",
    "3D相册体验中": "3D相册体验中",
    "3D相册体验结束": "3D相册体验结束",
    "您购买的3D相册正在精修中！": "您购买的3D相册正在精修中！",
    "请求失败，点击重新加载": "请求失败，点击重新加载",
    "没有更多了": "没有更多了",
    "查看3D相册": "查看3D相册",
    "倒计时": "倒计时",
    "天": "天",
    "待修模":"待修模",
    "修模中":"修模中",
    "待打印":"待打印",
    "待建模":"待建模",
    "打印中":"打印中",
    "未提交":"未提交",
    "快递中":"快递中",
    "称净重":"称净重",
    "建模中":"建模中",
    "3D相册已创建":"3D相册已创建",
    "后道工序处理":"后道工序处理",
    "后道工序处理中":"后道工序处理中",
    "订单详情": "订单详情",
    "订单信息": "订单信息",
    "订单ID":"订单ID",
    "拍摄时间":"拍摄时间",
    "复制":"复制",
    "重打":"重打",
    "加打":"加打",
    "首页":"首页",
    "订单":"订单",
    "我的订单":"我的订单",
    "订单记录": "订单记录",
    "联系门店": "联系门店",
    "拍摄门店": "拍摄门店",
    "费用合计": "费用合计",
    "复制成功": "复制成功",
    "复制失败": "复制失败",
    "保存成功": "保存成功",
    "暂无快递信息": "暂无快递信息",
    "退出登录中...": "退出登录中...",

    "渲染中...": "渲染中...",
    "数据加载中...": "数据加载中...",
    "故障模型，请联系门店处理": "故障模型，请联系门店处理",
    "3D相册体验已结束": "3D相册体验已结束",
    "您购买的3D相册正在精修中，请稍候！": "您购买的3D相册正在精修中，请稍候！",
    "您未购买3D相册": "您未购买3D相册",
    "体验还剩": "体验还剩",
    "模型问题反馈": "模型问题反馈",
    "我要认领": "我要认领",
    "纯色背景": "纯色背景",
    "已选择": "已选择",
    "主题背景": "主题背景",

    "复位": "复位",
    "保存": "保存",
    "旋转": "旋转",
    "停止": "停止",
    "换背景": "换背景",
    "收起": "收起",
    "展开": "展开",
    "体验AR": "体验AR",
    "我的":"我的",
    "关于我们":"关于我们",
    "语言":"语言",

    "头像":"头像",
    "账号":"账号",
    "性别":"性别",
    "退出登录":"退出登录",
    "确认退出登录？":"确认退出登录？",
    "确认退出":"确认退出",
    "我再想想":"我再想想",
    "个人中心":"个人中心",
    "请登录":"请登录",
    "删除订单":"删除订单",
    "取消":"取消",

    "退出AR":"退出AR",
    "简体中文":"简体中文",
    "英文":"英文",
    "繁体中文":"繁体中文",
    "韩文":"韩文",


    
}
export default zhCN