export const AllStatus = -1 // 默认所有状态
export const PlatformStatus = -2 // 平台方的状态，不包括未提交建模的
export const UploadingStatus = 0 // 上传状态
export const WaitingCustomerConfirmTakephotoStatus = 1000 // 上传完成
export const UploadedPhotoStatus = 1000 // 上传完成
export const WaitingPushMakeModelStatus = 2000 // 待门店提交建模
export const WaitingMakeModelStatus = 3000 // 待建模
export const ModelingStatus = 4000 // 建模中
export const ModelingComplateStatus = 4100 // 建模完成
export const WaitingModelRepairStatus = 5000 // 待修模
export const ModelRepairingStatus = 6000 // 修模中
export const ModelRepairedStatus = 7000 // 修模完成
export const PhotoAlbum3DCreatedStatus = 7011 // 3d相册已创建
export const WaitingPrintStatus = 7020 // 待打印
export const ModelPrintingStatus = 8000 // 模型打印中
export const ModelPrintedStatus = 8100 // 模型打印完成(填写毛重)
export const ModelPrintedStatusWithFilledNetWeight = 8110 // 模型打印完成(填写毛重)
export const ModelExpressingStatus = 9000 // 模型快递中（已完成填写快递单号）
export const ShopReceivedModelStatus = 10000 // 门店收到模型
export const CustomerReceiveModelStatus = 11000 // 客户收到模型
