<script setup lang="tsx">
defineProps({
  activity: {
    type: Object,
    default: () => ({}),
  },
})
function sortByTimeDesc(bjectInfo: any[]) {
  // 进行逆序降序排序
  const tempRes = (bjectInfo || []).filter((el: any) => el?.routes?.length > 0).sort((a, b) => {
    return new Date(b.acceptTime).getTime() - new Date(a.acceptTime).getTime()
  })
  return tempRes
}

function getAcceptTime(item) {
  if (item && item.routes && item.routes[0])
    return item.routes[0].acceptTime

  return ''
}

// function hasExpressRouter(routerList?: any[]) {
//   if (!routerList)
//     return false

//   return routerList.some((item) => {
//     return item.routes && item.routes.length > 0
//   })
// }
</script>

<template>
  <van-step
    v-for="(item, index1) in sortByTimeDesc(
      activity.routerInfo,
    )" :key="index1"
  >
    <h3>
      {{ item.routes[0] ? item.routes[0].acceptAddress : '' }}
      ({{ item.routes[0] ? item.routes[0].remark : '' }})
    </h3>
    <p>{{ getAcceptTime(item) }}</p>
  </van-step>
  <van-step>
    <h3>
      {{ activity.msg }} {{ activity.express_no
      }}
    </h3>
    <p>{{ activity.time }}</p>
  </van-step>
</template>

<style></style>
