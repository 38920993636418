<template>
    <div class="tabbar_wrap">
        <van-tabbar route v-model="active" active-color="#009944"  inactive-color="#AAAAAA">
            <!-- <van-tabbar-item to="/">
                <span class="tabbar_text">{{'首页'.$t}}</span>
                <template #icon="props">
                    <img class="tabbar_img" :src="props.active ? home.active : home.inactive" />
                </template>
            </van-tabbar-item> -->
            <van-tabbar-item to="/myOrder">
                <span class="tabbar_text">{{'订单'.$t}}</span>
                <template #icon="props">
                    <img class="tabbar_img" :src="props.active ? order.active : order.inactive" />
                </template>
            </van-tabbar-item>

            <van-tabbar-item to="/user">
                <span class="tabbar_text">{{'我的'.$t}}</span>
                <template #icon="props">
                    <img class="tabbar_img" :src="props.active ? user.active : user.inactive" />
                </template>
            </van-tabbar-item>
            <!-- <van-tabbar-item to="/message">
                <span>消息</span>
                <template #icon="props">
                    <img :src="props.active ? message.active : message.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/user">
                <span class="tabbar_text">我的</span>
                <template #icon="props">
                    <img class="tabbar_img" :src="props.active ? user.active : user.inactive" />
                </template>
            </van-tabbar-item> -->
        </van-tabbar>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
const active = ref(0);

import orderActive from '@/assets/common/my-order-select.png';
import orderInactive from '@/assets/common/my-order.png';
import homeActive from '@/assets/common/home-select.png';
import homeInactive from '@/assets/common/home.png';

import userActive from '@/assets/common/user-info-select.png';
import userInactive from '@/assets/common/user-info.png';

const order = {
    active: orderActive,
    inactive: orderInactive,
};
const home = {
    active: homeActive,
    inactive: homeInactive,
};
// const message = {
//     active: 'src/assets/common/message-select.png',
//     inactive: 'src/assets/common/message.png',
// };
const user = {
    active: userActive,
    inactive: userInactive,
};

</script>
<style lang="less" scoped>
:deep(.van-tabbar) {
    width: 375px;
}
</style>
