export function printType(val: number) {
  let type = ''
  switch (val) {
    case 1:
      type = '正常打印'
      break
    case 2:
      type = '重打'
      break
    case 3:
      type = '加打'
      break
    case 4:
      type = '样品'
      break
    default:
      break
  }
  return type
}

export function modelSize(val: string) {
  if (!val)
    return ''

  // 分割字符串
  const arrV = val.split('__')
  const data = []
  arrV.forEach((item) => {
    const temp = item.replace(/_x/g, '*').replace(/_/g, '')
    data.push(temp)
  })
  return data
}
